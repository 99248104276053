<template>
  <main>
    <CCard>
      <CAlert
        id="ChooseNotice"
        v-if="(ChooseIndex.length > 0)"
        color="info"
        :fade="false"
      >
          <span class="text">
            {{ $t('Global.SelectedLabel') }}
            <span class="font-weight-bold">{{ ChooseIndex.length }}</span>
            {{ $t('Global.ItemUnit') }}
          </span>
        <div class="ButtonGroup">
          <CButton color="light" size="sm" class="mr-2" @click="ChooseIndex = [];CheckAllValue = false">
            {{ $t('Global.Cancel') }}
          </CButton>
          <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" color="info" size="sm"
                   class="mr-2" @click="BulkEditModel = true">{{ $t('Global.BulkEdit') }}
          </CButton>
          <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('D')" color="danger" size="sm"
                   @click="AlertModel = true">{{ $t('Global.Delete') }}
          </CButton>
        </div>
      </CAlert>
      <CCardHeader>
        <CRow>
          <CCol col="12">
            <CButton color="white" shape="pill" class="mr-2" @click="FilterModel = true;ShowFilter = 'All'">
              <CIcon name="cil-filter"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Name')">
              {{ $t('Product/ListPlan.Name') }}: {{ SearchFilter.Name || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Status')">
              {{ $t('Product/List.Status') }}: {{ SearchFilter.Status ? $t('Product/List.StatusOptions.' + SearchFilter.Status) : '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Tags')">
              {{ $t('Product/Detail.Tags') }}: {{ SearchFilter.Tags.join(',') || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="dark" shape="pill" class="px-3 mr-2" @click="OpenFilter('Sort')">
              {{ $t('Product/Detail.Sort') }}: {{ $t('Product/List.SortType.' + this.Order) || '--' }}
              <CIcon size="sm" class="ml-1" name="cil-pencil"/>
            </CButton>
            <CButton size="sm" color="danger" shape="pill" @click="ResetFilter()">
              <CIcon size="sm" name="cil-x"/>
            </CButton>
          </CCol>
        </CRow>
      </CCardHeader>
      <CCardBody class="p-0">
        <CDataTable
          id="ProductList"
          :items="List"
          :fields="Field"
          :loading="Loading"
          :noItemsView="noItemsView"
          responsive
          hover
        >
          <template #Checkbox-header>
            <label class="mb-0">
              <input type="checkbox" id="CheckAll" v-model="CheckAllValue" @change="CheckAll()">
            </label>
          </template>
          <template #Checkbox="{item}">
            <td class="text-center">
              <label class="mb-0">
                <input type="checkbox" :id="'Checkbox-List-' + item.SeriesNum" :value="item.SeriesNum"
                       v-model="ChooseIndex">
              </label>
            </td>
          </template>
          <template #Cover="{item}">
            <td>
              <img v-lazy="item.Cover" width="60" height="60" class="img-fluid"/>
            </td>
          </template>
          <template #Amount="{item}">
            <td class="small text-nowrap">
              <template v-if="item.EnableOption === 1">
                <p class="mb-0"><b>價格區間</b>: <span class="text-danger">{{ $store.state.currency + '$' }}{{ item.Amount.RangeMin }}~{{ item.Amount.RangeMax }}</span></p>
              </template>
              <template v-else>
                <span class="text-danger">{{ $store.state.currency + '$' }}{{ item.Amount.Actual }}</span>
              </template>
            </td>
          </template>
          <template #Stock="{item}">
            <td class="small">
              <template v-if="item.EnableStock === 1">
                <p class="mb-0"><b>{{ $t('Product.RealStock') }}</b>: {{ item.Stock.RealStock }}</p>
                <p class="mb-0"><b>{{ $t('Product.SafeStock') }}</b>: {{ item.Stock.SafeStock }}</p>
                <p class="mb-0"><b>{{ $t('Product.MaxStock') }}</b>: {{ item.Stock.MaxStock }}</p>
              </template>
              <template v-else>
                <CBadge color="warning">
                  {{ $t('Product/List.UnableStock') }}
                </CBadge>
              </template>
            </td>
          </template>
          <template #Status="{item}">
            <td>
              <CBadge :color="(item.Status === 1 ? 'success' : 'danger')">
                {{ $t('Product.Status.' + item.Status) }}
              </CBadge>
            </td>
          </template>
          <template #Action="{item}">
            <td>
              <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')" color="info"
                       size="sm" class="mr-1" v-c-tooltip="$t('Product/List.Edit')"
                       @click="$router.push(`/products/plan/detail/${item.SeriesNum}`)">
                <CIcon name="cil-pencil" class="c-icon-custom-size mr-1"/>
              </CButton>
              <CButton v-if="$store.state.user.permission.Permission.Products.Items.includes('W')"
                       class="btn btn-sm btn-secondary mr-1" v-c-tooltip="$t('Global.BlankTarget')"
                       @click="OpenWindow(`/products/plan/detail/${item.SeriesNum}`)">
                <CIcon name="cil-clone" class="c-icon-custom-size mr-1"/>
              </CButton>
              <CButton class="btn btn-sm btn-secondary" v-c-tooltip="$t('Product/List.Duplicate')"
                       @click="Duplicate(item.SeriesNum)">
                <CIcon name="cil-copy" class="c-icon-custom-size mr-1"/>
              </CButton>
            </td>
          </template>
        </CDataTable>
      </CCardBody>
      <CCardFooter>
        <CPagination :activePage.sync="Pagination.Page" :pages.sync="Pagination.TotalPages"
                     @update:activePage="ChangePage()"/>
      </CCardFooter>
    </CCard>
    <CModal id="AlertModel" class="ActionModel" :show.sync="AlertModel" :centered="true" :closeOnBackdrop="!Submit">
      <CAlert color="danger">
        <CIcon name="cil-bell"/>
        {{ $t('Global.ConfirmDelete') }}
      </CAlert>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Delete') }}</h5>
          <CButtonClose @click="AlertModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#DeleteSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="AlertModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="DeleteSubmit" @click="Delete()" color="danger">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="BulkEditModel" class="ActionModel" :show.sync="BulkEditModel" :centered="true"
            :closeOnBackdrop="!Submit">
      <CRow form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Status') }}
        </CCol>
        <CCol sm="9">
          <CSwitch color="success" :checked.sync="BulkEditData.Status"/>
        </CCol>
      </CRow>
      <CInput
        :prepend="$store.state.currency + '$'"
        :label="$t('Product/Detail.Amount/Cost')"
        v-model="BulkEditData.Amount.Cost"
        type="number"
        horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <CInput
        :prepend="$store.state.currency + '$'"
        :label="$t('Product/Detail.Amount/Suggest')"
        v-model="BulkEditData.Amount.Suggest"
        type="number"
        horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <CInput
        :prepend="$store.state.currency + '$'"
        :label="$t('Product/Detail.Amount/Actual')"
        v-model="BulkEditData.Amount.Actual"
        type="number"
        horizontal>
        <template #append-content>
          <CIcon name="cil-dollar"/>
        </template>
      </CInput>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.BulkEdit') }}</h5>
          <CButtonClose @click="BulkEditModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#BulkEditSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="BulkEditModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="BulkEditSubmit" @click="BulkEdit()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
    <CModal id="FilterModel" class="ActionModel" :show.sync="FilterModel" :centered="true" :closeOnBackdrop="!Submit">
      <CInput v-if="ShowFilter === 'All' || ShowFilter === 'Name'" :label="$t('Product/ListPlan.Name')"
              v-model="SearchFilter.Name" horizontal/>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'SeriesNum'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Plan.SeriesNum') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.SeriesNum"
            v-model="SearchFilter.SeriesNum"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="SearchFilter.SeriesNum"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CSelect v-if="ShowFilter === 'All' || ShowFilter === 'Status'" horizontal :label="$t('Product/List.Status')" :options="StatusOptions" v-model="SearchFilter.Status" :value.sync="SearchFilter.Status" :placeholder="$t('Global.PleaseSelect')"/>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Tags'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Tags') }}
        </CCol>
        <CCol sm="9">
          <multiselect
            id="SearchFilter.Tags"
            v-model="SearchFilter.Tags"
            :optionHeight="24"
            :tag-placeholder="$t('Global.EnterToAdd')"
            :placeholder="''"
            :deselectLabel="$t('Global.DeselectLabel')"
            :selectedLabel="$t('Global.SelectedLabel')"
            :options="Tags"
            :multiple="true"
            :taggable="true"
            @tag="AddSearchFilter">
            <template #noOptions>
              {{ $t('Global.NoOptions') }}
            </template>
          </multiselect>
        </CCol>
      </CRow>
      <CRow v-if="ShowFilter === 'All' || ShowFilter === 'Sort'" form class="form-group">
        <CCol tag="label" sm="3" class="col-form-label">
          {{ $t('Product/Detail.Sort') }}
        </CCol>
        <CCol sm="9">
          <CInputRadioGroup v-model="Order" :options="SortOption" :checked.sync="Order" :custom="true" inline/>
        </CCol>
      </CRow>
      <template #header-wrapper>
        <header class="modal-header border-bottom-0">
          <h5 class="modal-title">{{ $t('Global.Filter') }}</h5>
          <CButtonClose @click="FilterModel = false"/>
        </header>
      </template>
      <template #footer-wrapper>
        <CElementCover
          v-if="(Submit === true)"
          :boundaries="[{ sides: ['center', 'center'], query: '#FilterModelSubmit' }]"
          :opacity="0.8"
        >
          <CSpinner size="sm" color="primary"/>
        </CElementCover>
        <footer class="modal-footer border-top-0">
          <CButton @click="FilterModel = false" color="light">{{ $t('Global.Cancel') }}</CButton>
          <CButton id="FilterModelSubmit" @click="ChangePage()" color="success">
            {{ $t('Global.Confirm') }}
          </CButton>
        </footer>
      </template>
    </CModal>
  </main>
</template>

<route>
{
"meta": {
"label": "方案一覽"
}
}
</route>

<script>
export default {
  name: 'ProductsListPlan',
  layout: 'manage',
  components: {
    Multiselect: () => import('@/plugins/mutiselect'),
  },
  data() {
    return {
      List: [],
      Tags: [],
      ShowFilter: 'All',
      SearchFilterType: null,
      SearchFilter: {
        SeriesNum: [],
        Tags: [],
        Name: '',
        Status: ''
      },
      AlertModel: false,
      BulkEditModel: false,
      FilterModel: false,
      BulkEditData: {
        Status: true,
        Brand: '',
        Amount: {
          Suggest: '',
          Actual: '',
          Cost: ''
        }
      },
      ChooseIndex: [],
      CheckAllValue: false,
      Pagination: {
        Page: 1,
        PerPage: 10,
        TotalItems: 0,
        TotalPages: 0
      },
      Order: 'create_nto',
      Loading: false,
      Submit: false,
      noItemsView: {
        noResults: this.$t('Product/List.NoResults'),
        noItems: this.$t('Product/List.NoItems')
      }
    }
  },
  computed: {
    Field() {
      return [
        {key: 'Checkbox', label: ''},
        {key: 'Cover', label: this.$t('Product/List.Cover')},
        {key: 'Name', label: this.$t('Product/ListPlan.Name')},
        {key: 'Amount', label: this.$t('Product/List.Amount')},
        {key: 'SeriesNum', label: this.$t('Product/Plan.SeriesNum')},
        {key: 'Status', label: this.$t('Product/List.Status')},
        {key: 'Action', label: ''}
      ]
    },
    StatusOptions() {
      return Object.keys(this.$t('Product/List.StatusOptions')).map(value => {
        return {
          label: this.$t('Product/List.StatusOptions.' + value),
          value
        }
      })
    },
    SortOption() {
      return [
        {value: 'otn', label: this.$t('Product/List.SortType.otn')},
        {value: 'nto', label: this.$t('Product/List.SortType.nto')},
        {value: 'lth', label: this.$t('Product/List.SortType.lth')},
        {value: 'htl', label: this.$t('Product/List.SortType.htl')}
      ]
    }
  },
  mounted() {
    this.$Progress.start()
    this.Init().then(() => {
      this.$Progress.finish()
    }).catch((err) => {
      this.$Progress.fail()
      this.$notify({
        group: 'notify',
        title: this.$t('Message.Error'),
        text: this.$t('Message.Global/ErrorMessage') + err.msg,
        type: (err.type ? err.type : 'error')
      })
    })
  },
  methods: {
    Init() {
      return Promise.all([
        this.GetList(),
      ]).then(() => {
        this.Submit = false
        Object.keys(this.$route.query).forEach((param) => {
          switch (param) {
            case 'page':
            case 'perPage':
            case 'order':
              break
            case 'name':
              this.SearchFilter.Name = this.$route.query.name
              break
            case 'status':
              this.SearchFilter.Status = this.$route.query.status
              break
            case 'seriesNum':
              this.SearchFilter.SeriesNum = this.$route.query.seriesNum.split(',')
              break
            case 'tags':
              this.SearchFilter.Tags = this.$route.query.tags.split(',')
              break
          }
        })
        return true
      }).catch((err) => {
        this.Submit = false
        throw err
      })
    },
    GetList() {
      let RequestQuery = {
        page: this.$route.query.page || this.Pagination.Page,
        perPage: this.$route.query.perPage || this.Pagination.PerPage,
        order: this.$route.query.order || this.Order,
        taxonomy: 'Plan'
      }
      //todo 判斷確認 要調整
      if (this.$route.query.name) {
        RequestQuery.name = this.$route.query.name
      }
      if (this.$route.query.status) {
        RequestQuery.status = this.$route.query.status
      }
      if (this.$route.query.seriesNum) {
        RequestQuery.seriesNum = this.$route.query.seriesNum
      }
      if (this.$route.query.tags) {
        RequestQuery.tags = this.$route.query.tags
      }
      this.List = []
      this.Loading = true
      this.FilterModel = false
      return this.$store.dispatch('InnerRequest', {
        url: '/product/list',
        method: 'get',
        params: RequestQuery
      }).then(({data}) => {
        this.Loading = false
        this.List = data.list
        this.Tags = data.tags.map(item => item.Name)
        this.Pagination = data.pagination
      }).catch((err) => {
        this.Loading = false
        throw err
      })
    },
    ChangePage() {
      const query = {
        page: this.Pagination.Page,
        perPage: this.Pagination.PerPage,
        order: this.Order,
      }
      //todo 判斷確認 要調整
      if (this.SearchFilter.Name) {
        query.name = this.SearchFilter.Name
      }
      if (this.SearchFilter.Status) {
        query.status = this.SearchFilter.Status
      }
      if (this.SearchFilter.SeriesNum.length > 0) {
        query.seriesNum = this.SearchFilter.SeriesNum.join(',')
      }
      if (this.SearchFilter.Tags.length > 0) {
        query.tags = this.SearchFilter.Tags.join(',')
      }
      this.$router.replace({
        path: '/products/plan/list',
        query
      }).then(() => {
        this.Init()
      })
    },
    Delete() {
      this.Submit = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/delete',
        method: 'post',
        data: {
          taxonomy: 'Plan',
          seriesNum: this.ChooseIndex
        }
      }).then(() => {
        this.Init()
        this.Submit = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/DeleteSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Init()
        this.Submit = false
        this.AlertModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Product/DeleteFail') + err.msg,
          type: 'error'
        })
      })
    },
    BulkEdit() {
      this.Submit = true
      const updateData = {}
      Object.keys(this.BulkEditData).forEach((index) => {
        //驗證是否為物件不為陣列
        switch (typeof this.BulkEditData[index]) {
          case 'object':
            if (!Array.isArray(this.BulkEditData[index])) {
              Object.keys(this.BulkEditData[index]).forEach((subIndex) => {
                if (this.BulkEditData[index][subIndex] !== '') {
                  updateData[index + '.' + subIndex] = this.BulkEditData[index][subIndex]
                }
              })
            } else {
              updateData[index] = this.BulkEditData[index]
            }
            break
          default:
            if (this.BulkEditData[index] !== '') {
              updateData[index] = this.BulkEditData[index]
            }
            break
        }
      })
      return this.$store.dispatch('InnerRequest', {
        url: '/product/bulkEdit',
        method: 'post',
        data: {
          seriesNum: this.ChooseIndex,
          updateData
        }
      }).then(() => {
        this.Init()
        this.BulkEditModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/BulkEditSuccess'),
          type: 'success'
        })
      }).catch((err) => {
        this.Init()
        this.BulkEditModel = false
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Global/ErrorMessage') + err.msg,
          type: 'error'
        })
      })
    },
    CheckAll() {
      if (this.CheckAllValue === true) {
        this.ChooseIndex = this.List.map(data => {
          return data.SeriesNum
        })
      } else {
        this.CheckAllValue = false
        this.ChooseIndex = []
      }
    },
    AddSearchFilter(newTag, id) {
      id = id.replace('SearchFilter.', '')
      this.SearchFilter[id].push(newTag.trim())
    },
    OpenFilter(type = 'All') {
      this.FilterModel = true
      this.ShowFilter = type
    },
    ResetFilter() {
      this.Submit = false
      this.AlertModel = false
      this.BulkEditModel = false
      this.BulkEditData = {
        Status: true,
        Amount: {
          Suggest: '',
          Actual: '',
          Cost: ''
        }
      }
      this.SearchFilter = {
        SeriesNum: [],
        Tags: [],
        Name: '',
        Status: ''
      }
      this.ChooseIndex = []
      this.$router.replace({
        path: '/products/plan/list'
      }).then(() => {
        this.Init()
      })
    },
    OpenWindow(_link) {
      return window.open(_link, '_blank');
    },
    Duplicate(SeriesNum) {
      this.Loading = true
      return this.$store.dispatch('InnerRequest', {
        url: '/product/duplicate',
        method: 'get',
        params: {
          taxonomy: 'Plan',
          seriesNum: SeriesNum
        }
      }).then(() => {
        this.GetList()
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Success'),
          text: this.$t('Message.Product/SuccessCopy'),
          type: 'success'
        })
      }).catch((error) => {
        console.log(error)
        this.$notify({
          group: 'notify',
          title: this.$t('Message.Error'),
          text: this.$t('Message.Product/FailCopy') + error.msg,
          type: 'error'
        })
      })
    }
  }

}
</script>

<style>
#ChooseNotice {
  position: absolute;
  z-index: 10;
  width: 100%;
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
  padding: 0.5rem 1.25rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#ProductList thead th:first-child {
  text-align: center;
}

#ProductList tbody td:first-child {
  vertical-align: middle;
}
</style>
